import api from '@/services/api'

export default {
  state: {},

  mutations: {},

  actions: {
    async saveLoja({ commit }, loja) {
      try {
        return await api.post('/lojas', loja)
      } catch (error) {
        throw error
      }
    },

    async getLojas({ commit }) {
      try {
        const response = await api.get('/lojas')

        return response && response.data ? response.data : null
      } catch (error) {
        throw error
      }
    },

    async getLoja({ commit }, lojaId) {
      try {
        const response = await api.get(`/lojas/${lojaId}`)

        return response && response.data ? response.data : null
      } catch (error) {
        throw error
      }
    },

    async updateLoja({ commit }, loja) {
      console.log(loja.id)
      try {
        return await api.put(`/lojas/${loja.id}`, loja)
      } catch (error) {
        throw error
      }
    },
  },

  getters: {},
}
