const BASE_URL = '/'
const AUTH = BASE_URL
const PASSWORD_RECOVERY = `${BASE_URL}password-recovery`
const NEW_PASSWORD = `${BASE_URL}new-password/:key`
const HOME = `${BASE_URL}home`
const ADM = `${BASE_URL}administration`
const ADM_USERS = `${ADM}/users`
const ADM_CHAVES = `${ADM}/chaves`
const ADM_DADOS = `${ADM}/users/meusDados`
const ADM_LOJAS = `${ADM}/lojas`
const ADM_RASGADINHAS = `${ADM}/CadastrarRasgadinhas`
const ADM_MINHAS_RASGADINHAS = `${ADM}/MinhasRasgadinhas`
const PARTICIPANTE = `${BASE_URL}CadastrarParticipante`
const BACKOFFICE = `${BASE_URL}backoffice`
const BACKOFFICE_CLIENTS = `${BACKOFFICE}/clients`
const BACKOFFICE_DETAILS_CLIENT = `${BACKOFFICE_CLIENTS}/:clientId`
const UNAUTHORIZED = `${BASE_URL}not-authorized`

export default {
  BASE_URL,
  AUTH,
  PASSWORD_RECOVERY,
  NEW_PASSWORD,
  HOME,
  ADM,
  ADM_USERS,
  ADM_LOJAS,
  BACKOFFICE,
  BACKOFFICE_CLIENTS,
  BACKOFFICE_DETAILS_CLIENT,
  UNAUTHORIZED,
  PARTICIPANTE,
  ADM_RASGADINHAS,
  ADM_MINHAS_RASGADINHAS,
  ADM_DADOS,
  ADM_CHAVES,
}
