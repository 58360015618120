import auth from './modules/auth/auth'
import layouts from './modules/layout/layouts'
import users from './modules/user/users'
import lojas from './modules/loja/lojas'
import chaves from './modules/chaves/chaves'
import cadastrarParticipante from './modules/user/cadastrarUsuario'
import modules from './modules/module/modules'
import clients from './modules/client/clients'
import projects from './modules/client/projects'

export default {
  auth,
  layouts,
  users,
  lojas,
  modules,
  clients,
  projects,
  cadastrarParticipante,
  chaves,
}
