import api from '@/services/api'
import store from '../..'

export default {
  state: {},

  mutations: {},

  actions: {
    async saveChaves({ commit }, chave) {
      try {
        return await api.post('/chaves', chave)
      } catch (error) {
        throw error
      }
    },

    async saveRasgadinhas({ commit }, data) {
      try {
        return await api.post(
          `chaves/${data.lojaId}/registerChaveByUser`,
          data.chaves,
        )
      } catch (error) {
        throw error
      }
    },

    async getChaves({ commit }) {
      try {
        const response = await api.get('/chaves')

        return response && response.data ? response.data : null
      } catch (error) {
        throw error
      }
    },

    async getChavesByUser({ commit }) {
      try {
        const userId = store.getters.user.id
        const response = await api.get(`/chaves/${userId}/byUser`)
        return response && response.data ? response.data : null
      } catch (error) {
        throw error
      }
    },
    async getChaveByChave({ commit }, data) {
      try {
        console.log(data)
        const response = await api.get(`/chaves/${data}/infoChave`)
        return response && response.data ? response.data : null
      } catch (error) {
        throw error
      }
    },
  },

  getters: {},
}
