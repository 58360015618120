import api from '@/services/api'

export default {
  state: {
    user: {
      id: 0,
      name: '',
      modules: null,
    },
  },

  mutations: {},

  actions: {
    async saveUser({ commit }, user) {
      try {
        return await api.post('/register', user)
      } catch (error) {
        throw error
      }
    },
    async getStates({ commit }) {
      try {
        const response = await api.get('/states')

        return response && response.data ? response.data : null
      } catch (error) {
        throw error
      }
    },

    async getCities({ commit }, stateId) {
      try {
        const response = await api.get(`/cities/${stateId}`)

        return response && response.data ? response.data : null
      } catch (error) {
        throw error
      }
    },
  },

  getters: {},
}
